import React, { useEffect, useState } from "react";
import { Table,Form, Input, Button,message } from "antd";
import useHttp from "../../hooks/useHttp";
import useModal from "../../hooks/useModal";
import AdminLayout from "../../components/AdminLayout";
const AdminUser = ({ match }) => {
  const { authPost } = useHttp();
  const [users, setUsers] = useState([]);
  const [editUserInfo, setEditUserInfo] = useState({})
  const editModal = useModal(500);
  const loadData = async () => {
    let data = await authPost("/api/AdminUsers", {});

    setUsers(data.payload.map(it=>({
        ...it,
        key:it.id
    })));
  };
  const columns = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "用户名",
      dataIndex: "uname",
      key: "uname",
    },
    {
      title: "密码",
      dataIndex: "pass",
      key: "pass",
    },
    {
        title:'操作',
        key:'x',
        render:(it)=>{
            return <a onClick={()=>onEditUser(it.id)}>编辑</a>
        }
    }
  ];
  const onEditUser=async (userId)=>{
    let res = await authPost('/api/adminUserInfo',{userId});
    setEditUserInfo(res.payload);
    editModal.openModal();
  }
  const onEditFinished=async(values)=>{
    let da = {...editUserInfo};
    if(!values.pass){
        message.error('请填写密码');
        return;

    }
    da={
        ...da,
        ...values,
    };
    let res = await authPost('/api/SaveAdminUserPass',da);
    editModal.closeModal();
    await loadData();
  }
  useEffect(() => {
    loadData();
    return () => {};
  }, [match.path]);
  return (
    <AdminLayout>
      <Table size="middle" dataSource={users} columns={columns} />
      <editModal.Modal>
          <Form onFinish={onEditFinished} labelCol={{span:6}} wrapperCol={{span:24}} style={{margin:'10px'}} initialValues={editUserInfo}>
              <Form.Item name='uname' label='用户名'>
                  <Input readOnly={true} disabled/>
              </Form.Item>
              <Form.Item name='pass' label='密码' rules={
                  [{required:true,message:'请填写密码'}]
              }>
                  <Input />
              </Form.Item>
              <Form.Item style={{textAlign:'center'}}>
                  <Button htmlType='submit' type='primary'>
                      修改
                  </Button>
              </Form.Item>
          </Form>
      </editModal.Modal>
    </AdminLayout>
  );
};

export default AdminUser;
