import React, { useState } from "react";
import { Modal } from "antd";
const useModal = (width = 600) => {
  const [isopen, setIsopen] = useState(false);
  const confirm = ({title,content}) => {
    return new Promise((done, reject) => {
      Modal.confirm({
        title: title,
        content: content,
        cancelText: "取消",
        okText: "确定",
        onCancel: () => {done(false)},
        onOk: async () => {done(true)},
      });
    });
  };
  return {
    Modal: ({ children }) => {
      return (
        <Modal
          width={width}
          closeIcon={null}
          footer={null}
          visible={isopen}
          onCancel={() => setIsopen(false)}
        >
          {children}
        </Modal>
      );
    },
    closeModal: () => setIsopen(false),
    openModal: () => setIsopen(true),
    confirm
  };
};

export default useModal;
