import React from 'react'
import axios from 'axios'
import useAuth from './useAuth'

const useHttp = () => {
    const {getToken} = useAuth();
    const authPost=async(url,data)=>{
        const res = await axios.post(url,data,{
            headers:{
                'Authorization': 'Bearer ' + getToken()
            }
        })
        return res.data;
    }
    return {
        authPost
    }
}

export default useHttp
