import React from 'react'
import AdminLayout from '../components/AdminLayout'
const AdminHome = () => {
    return (
        <AdminLayout>
            
        </AdminLayout>
    )
}

export default AdminHome
