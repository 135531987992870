import React from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import {post} from '../services/HttpService'
import styled from "styled-components";
import useAuth from "../hooks/useAuth";
const PageOuter = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const LoginForm = styled(Form)`
  border: 1px solid #eee;
  padding: 20px;
  width: 450px;
  border-radius: 6px;
`;
const { toLogin } = useAuth();
const AdminLogin = ({ history }) => {
  const getRequiredMesage = (msg) => {
    return [
      {
        required: true,
        message: msg,
      },
    ];
  };
  const onFinished = async (value) => {
    let res = await post('/auth/adminlogin',value);
    if (res.ok) {
      let token = res.payload.token;
      toLogin(token);
      history.replace("/admin/home");
    } else {
      message.error("登录用户名或密码错误");
    }
  };
  return (
    <PageOuter>
      <LoginForm
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinished}
      >
        <Form.Item
          label="用户名"
          name="uname"
          rules={getRequiredMesage("请填写用户名")}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="登录密码"
          name="pass"
          rules={getRequiredMesage("请填写登录密码")}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item style={{ textAlign: "center", marginBottom: 0 }}>
          <Button type="primary" htmlType="submit">
            登录
          </Button>
        </Form.Item>
      </LoginForm>
    </PageOuter>
  );
};

export default AdminLogin;
