import { Button, message, Table,Modal } from "antd";
import React, { useEffect, useState } from "react";
import AdminLayout from "../../components/AdminLayout";
import useAuth from "../../hooks/useAuth";
import useHttp from "../../hooks/useHttp";
import useModal from "../../hooks/useModal";
import ZsEdit from "./ZsEdit";

const ZsNames = () => {
  const [data, setData] = useState([]);
  const [editInfo, setEditInfo] = useState({});
  const { authPost } = useHttp();
  const editModal = useModal();

  const loadData = async () => {
    let res = await authPost("/api/GetAllZsNames", {});
    if (res.ok) {
      setData(res.payload.map((it) => ({ ...it, key: it.id })));
    }
  };

  useEffect(() => {
    loadData();
  }, []);
  const onAddZs = () => {
    setEditInfo({});
    editModal.openModal();
  };
  const onZsEditSaved = async (values) => {
      let postData = {
        ...editInfo,
        ...values,
      };
      if(postData.istop===''){
          delete postData.istop;
      }
    const res = await authPost("/api/SaveZsName",postData );
    if (res.ok) {
      editModal.closeModal();
      await loadData();
    } else {
      message.error(res.message);
    }
  };
  const onEditLinkClicked=async (id)=>{
    let res = await authPost('/api/GetZsInfo',{id});
    if(res.ok){
        setEditInfo(res.payload);
        editModal.openModal();
    }
  }
  const onDeleteLinkClicked=async (id)=>{
        let confirm = await editModal.confirm({title:'确认删除',content:'是否确认删除'});
        if(confirm){
            let res = await authPost('/api/DelZsIt',{id});
            if(res.ok){
                await loadData();
            }
        }
  }
  const columns = [
    {
      title: "名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "排序号",
      dataIndex: "istop",
      key: "istop",
    },
    {
      title: "操作",
      key: "action",
      render: (zs) => {
        return (
          <>
            <a onClick={()=>onEditLinkClicked(zs.id)}>编辑</a>
            {' '}
            <a onClick={()=>onDeleteLinkClicked(zs.id)}>删除</a>
          </>
        );
      },
    },
  ];
  return (
    <AdminLayout>
      <div style={{marginBottom:'15px'}}>
        <Button type="primary" onClick={onAddZs}>
          添加证书类型
        </Button>
      </div>
      <Table dataSource={data} columns={columns} />
      <editModal.Modal>
        <ZsEdit info={editInfo} onSave={onZsEditSaved} />
      </editModal.Modal>
    </AdminLayout>
  );
};

export default ZsNames;
