import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import qrcode from "../images/qrcode.jpg";
import wx_search from "../images/wx_search.png";
import guanzu from "../images/guanzu.png";
import menu from "../images/menu.png";

const NoteOuter = styled.div`
  & > img {
    width: 95%;
    margin: 8px auto;
  }
  & > p {
    width: 95%;
    display: block;
    margin: 8px auto;
    font-size: 16px;
    color: #7d1a03;
    font-weight: bold;
  }
`;
const WxSubscribeNote = () => {
  return (
    <Layout>
      <NoteOuter>
        <img src={qrcode} />
        <p>识别上图二维码打开“嘉兴考试培训网服务号”</p>
        <img src={wx_search} />
        <p>或者微信搜一搜“嘉兴考试培训网服务号”</p>
        <img src={guanzu} />
        <p>点击“关注”按钮，关注“嘉兴考试培训网服务号”</p>
        <img src={menu} />
        <p>进入服务号后点击菜单中:考试信息-&gt;证书快递申请</p>
      </NoteOuter>
    </Layout>
  );
};

export default WxSubscribeNote;
