import React, { useEffect } from "react";
import { Layout, Menu } from "antd";
import useAuth from "../hooks/useAuth";
import { urls } from "../constants";
import { Link,withRouter } from "react-router-dom";
const AdminLayout = ({ children,history,match }) => {
  const { isLogin,quit } = useAuth();
  const chkIsLogin = async () => {
    const isAuth = await isLogin();
    if (!isAuth) {
      history.replace(urls.Login);
    }
  };
  useEffect(() => {
    chkIsLogin();
    return () => {};
  }, [match.path]);
  const onQuitClicked=()=>{
    quit();
    history.replace(urls.Login);
  }
  return (
    <Layout style={{ height: "100vh", width: "100vw" }}>
      <Layout.Sider>
        <Menu theme="dark">
          <Menu.Item>
            <Link to="/admin/infos">证书申请信息</Link>
          </Menu.Item>
          <Menu.Item>
            <Link to='/admin/zsnames'>证书分类管理</Link>
          </Menu.Item>
          <Menu.Item>
            
            <Link to="/admin/users">管理员管理</Link>
          </Menu.Item>
          <Menu.Item>
            <a onClick={onQuitClicked}>安全退出</a>
          </Menu.Item>
        </Menu>
      </Layout.Sider>
      <Layout.Content style={{ backgroundColor: "white", padding: "10px",height:'100vh',overflow:'auto' }}>
        {children}
      </Layout.Content>
    </Layout>
  );
};

export default withRouter(AdminLayout);
