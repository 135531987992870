import React, { useState } from "react";
import styled from "styled-components";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
const MenuOuter = styled.div`
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  border-bottom: 1px solid #ddd;
  z-index: 1000;
`;
const FirstOuter = styled.div`
  width: 100vw;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 15px;
  & > div.SysTitle {
    font-size: 18px;
    font-weight: bold;
    color: #2a6eb7;
    line-height: 40px;
  }
`;
const ChildMenuOuter = styled.div`
  display: flex;
  flex-direction: column;
  & > div.child-menu-item {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
    & > a {
      padding-left: 15px;
      display: block;
      font-size: 15px;
      color: #5d5e5f;
      font-weight: bold;
    }
  }
`;

const TopMenu = () => {
  const [showMenu, setShowMenu] = useState(false);
  const renderIcons = () => {
    return showMenu ? (
      <CloseOutlined onClick={() => setShowMenu(false)} />
    ) : (
      <MenuOutlined onClick={() => setShowMenu(true)} />
    );
  };
  const onMenuLinkClick = () => {
    setShowMenu(false);
  };
  const renderChildMenus = () => {
    return (
      showMenu && (
        <ChildMenuOuter>
          <div className="child-menu-item">
            <Link to="/FirstPage" onClick={onMenuLinkClick}>
              首页
            </Link>
          </div>

          <div className="child-menu-item">
            <Link to="/enterinfo" onClick={onMenuLinkClick}>
              纸质证书申领
            </Link>
          </div>
          <div className="child-menu-item">
            <Link to="/sendInfos" onClick={onMenuLinkClick}>
              我的证书申领
            </Link>
          </div>
          <div className="child-menu-item">
            <Link to="/OnlineStep" onClick={onMenuLinkClick}>
              电子证书申领
            </Link>
          </div>
        </ChildMenuOuter>
      )
    );
  };
  return (
    <MenuOuter>
      <FirstOuter>
        <div className="SysTitle">专业技术人员资格证书申领</div>
        <div>{renderIcons()}</div>
      </FirstOuter>
      {renderChildMenus()}
    </MenuOuter>
  );
};

export default TopMenu;
