import {Input, message, Space, Table, Button, Form, DatePicker, Select} from "antd";
import locale from "antd/lib/date-picker/locale/zh_CN";
import React, { useState, useEffect } from "react";
import AdminLayout from "../../components/AdminLayout";
import moment from "moment";
import useHttp from "../../hooks/useHttp";
import useModal from "../../hooks/useModal";
import InfoEdit from "./InfoEdit";
import "moment/locale/zh-cn";
moment.locale("zh-cn");


const Infos = ({ match }) => {
  const [data, setData] = useState({
    dataSource: [],
  });
  const [editInfo, setEditInfo] = useState({});
  const [keyword, setKeyword] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const [shType,setShType]=useState(-1)
  const { authPost } = useHttp();
  const editModal = useModal(800);
  const onEditClicked = async (id) => {
    let da = await authPost("/api/GetInfoById", { id });
    setEditInfo(da.payload);
    editModal.openModal();
  };
  const onEditSaved = async (value) => {
    value = {
      ...editInfo,
      ...value,
    };
    
    let saveResult = await authPost("/api/SaveInfo", value);
    if (saveResult.ok) {
      editModal.closeModal();
      loadData(keyword);
    } else {
      message.error(saveResult.message);
    }
  };
  const onDeleteClick=(id)=>{
    
  }
  const loadData = async ({ keyword }) => {
    let searchData = { key: keyword };
    if (dateRange && dateRange.length == 2) {
      searchData.dateRange = dateRange.map((it) => it.format("YYYY-MM-DD"));
    }
    searchData.shType=shType;
    let res = await authPost("/api/AllInfos", searchData);
    let tempData = res.payload.map((it) => ({
      key: it.id,
      ...it,
    }));

    setData({
      ...data,
      dataSource: tempData,
    });
  };
  const onExportExcel=async()=>{
    let searchData = {
      key:keyword,
      dateRange:dateRange.map(it=>it.format('YYYY-MM-DD'))
    };
    let res = await authPost('/api/ExportInfoToExcel',searchData);
    if(res.ok){
      window.open(res.payload);
    }
  }
  const renderSendStatusComponent=(it)=>{
    if(it.isSended){
      return (
        <>
        <span style={{color:'green'}}>已发</span>
        {' '}
        <span>{it.senderCompany}</span>
        </>
      )
    }else{
      return <span style={{color:'red'}}>未发</span>
    }
    return null;
  }
  const columns = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "姓名",
      key: "name",
      render:(it)=>{
        return <a onClick={()=>onEditClicked(it.id)}>{it.name}</a>
      }
    },
    {
      title:'电话',
      dataIndex:'tel',
      key:'tel',
    },
    
    {
      title: "证书名称",
      dataIndex: "zsName",
      key: "zsName",
    },
    {
      title: "年份",
      dataIndex: "year",
      key: "year",
    },
    {
      title: "申请时间",
      dataIndex: "addTime",
      key: "addTime",
      render: (it) => <span>{it && moment(it).format("YYYY-MM-DD")}</span>,
    },
    {
      title: "操作时间",
      dataIndex: "modifyTime",
      key: "modifyTime",
      render(time) {
        return <span>{time && moment(time).format("YYYY-MM-DD")}</span>;
      },
    },
    {
      title: "快递状态",
      key: "send",
      render(it) {
        return renderSendStatusComponent(it);
      },
    },
    {
      title: "操作",
      key: "action",
      render(it) {
        let {isOperated,id,isSh}=it;
        let color = isOperated?"gray":'blue';
        return <>
        <a style={{color:color}} onClick={() => onEditClicked(it.id)}>
          {isSh===null?'未审核':
            isSh===true?'审核通过':"审核不通过"
          }
        </a>
        {' '}
        {isSh===false && <a onClick={()=>onDeleteClick(id)}>删除</a>}
        </>;
      },
    },
  ];
  useEffect(() => {
    loadData({ keyword: "" });
    return () => {};
  }, [match]);
  const onSearch = () => {
    //setKeyword(value);
    loadData({ keyword: keyword });
  };
  return (
    <AdminLayout>
      <div style={{ marginBottom: "10px" }}>
        <Form
          style={{ display: "flex", flexDirection: "row" }}
          onFinish={onSearch}
        >
          <Input.Group compact style={{ flex: 1 }}>
            <Input
              placeholder="搜索关键字：姓名，证书名称"
              style={{ width: "30%" }}
              onChange={(v) => setKeyword(v.target.value)}
            />
            <Select onChange={(v)=>setShType(v)} style={{width:'30%'}} defaultValue={-1}>
              <Select.Option value={-1}>所有审核状态</Select.Option>
              <Select.Option value={0}>未审核</Select.Option>
              <Select.Option value={1}>已审核</Select.Option>
            </Select>
            <DatePicker.RangePicker
              style={{ width: "40%" }}
              locale={locale}
              name="daterange"
              onChange={(v) => setDateRange(v)}
            />
          </Input.Group>
          <Button type="primary" htmlType="submit">
            搜索
          </Button>
          {' '}
          <Button style={{marginLeft:5}} type="primary" onClick={onExportExcel} htmlType="button">
            导出Excel
          </Button>
          
        </Form>
        {/* <Input.Search
          enterButton="搜索"
          placeholder="搜索关键字"
          onSearch={onSearch}
        /> */}
      </div>
      <Table
        pagination={{ pageSize: 20 }}
        columns={columns}
        dataSource={data.dataSource}
      />
      <editModal.Modal>
        <InfoEdit info={editInfo} onSave={onEditSaved} />
      </editModal.Modal>
    </AdminLayout>
  );
};

export default Infos;
