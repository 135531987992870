import React, { useState, useEffect } from "react";
import { List } from "antd";
import { CheckOutlined, ClockCircleOutlined } from "@ant-design/icons";
import Layout from "../components/Layout";
import useWxAuth from "../hooks/useWxAuth";
import { post } from "../services/HttpService";
import styled from "styled-components";

const ListItemTitleOuter = styled.div`
  font-size: 14px;
  line-height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  span.isSenderText{
      font-size:14px;
      color:green;
      margin-right:8px;
      margin-left:2px;

  }
  span.notSenderText{

      font-size:14px;
      color:red;
      margin-right:8px;
      margin-left:2px;
  }
`;
const SendInfos = ({history}) => {
  const { getUserCode,chkWxIsAuthed } = useWxAuth();
  const [data, setData] = useState([]);
  const loadData = async () => {
    chkWxIsAuthed();
    let da = {
      code: getUserCode(),
    };

    let res = await post("/userapi/SendInfos", da);
    setData(res.payload);
  };
  useEffect(() => {
      
    loadData();
    return () => {};
  }, []);
  const renderIsSenderIconAndText = (isSender) => {
    if (isSender) {
      return (
        <>
          <CheckOutlined style={{ color: "#28a745" }} />
          <span className='isSenderText'>已发</span>
        </>
      );
    } else {
      return (
        <>
          <ClockCircleOutlined style={{ color: "red" }} />
          <span className='notSenderText'>未发</span>
        </>
      );
    }
  };
  const renderListItemTitle = (item) => {
    return (
      <ListItemTitleOuter>
        {item.isSended
          ? renderIsSenderIconAndText(true)
          : renderIsSenderIconAndText(false)}
        <span style={{marginRight:'4px'}}>年份:{item.year}</span> <span>证书：{item.zsName}</span>
      </ListItemTitleOuter>
    );
  };
  const renderListItem = (item) => {
    return (
      <List.Item style={{ padding: "0 8px" }} onClick={()=>history.push('/SendInfoDetail',{id:item.id})}>
        <List.Item.Meta
          title={renderListItemTitle(item)}
          description={`联系人：${item.name}  电话：${item.tel}`}
        />
      </List.Item>
    );
  };
  return (
    <Layout>
      <List
        dataSource={data}
        itemLayout="horizontal"
        renderItem={renderListItem}
      ></List>
    </Layout>
  );
};

export default SendInfos;
