import React from 'react'
import {Form,Input,Button}from 'antd'
const ZsEdit = ({info,onSave}) => {
    return (
       <Form onFinish={onSave} initialValues={info} labelCol={{span:5}} wrapperCol={{span:24}}>
           <Form.Item label='证书名称' name='name' rules={[{required:true,message:'请填写证书名称'}]}>
               <Input />
           </Form.Item>
           
           <Form.Item label='排序号' name='istop'>
               <Input placeholder='显示列表依据此设置升序排列' />
           </Form.Item>
           <Form.Item>
               <div style={{textAlign:'center'}}>
                   <Button type='primary' htmlType='submit'>保存</Button>
               </div>
           </Form.Item>

       </Form>
    )
}

export default ZsEdit
