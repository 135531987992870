import React, { Component } from 'react';
import {BrowserRouter,Route,Switch} from 'react-router-dom'
import 'antd/dist/antd.css';
import './custom.css'
import AdminLogin from './pages/AdminLogin';
import Home from './pages/Home';
import {urls} from './constants'
import AdminHome from "./pages/AdminHome";
import Infos from "./pages/admin/Infos";
import AdminUser from './pages/admin/AdminUser';
import EnterInfo from './pages/EnterInfo';
import Authed from './pages/Authed';
import FirstPage from './pages/FirstPage';
import OnlineStep from './pages/OnlineStep';
import SendInfos from './pages/SendInfos';
import SendInfoDetail from './pages/SendInfoDetail';
import ZsNames from './pages/admin/ZsNames';
import WxSubscribeNote from './pages/WxSubscribeNote';
export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path={urls.Login} component={AdminLogin} />
          <Route exact path="/admin/home" component={AdminHome} />
          <Route exact path="/admin/infos" component={Infos} />
          <Route exact path="/admin/users" component={AdminUser} />
          <Route exact path="/admin/zsnames" component={ZsNames} />
          <Route exact path="/enterinfo" component={EnterInfo} />
          <Route exact path="/authed" component={Authed} />
          <Route exact path="/subscribeNote" component={WxSubscribeNote} />
          <Route exact path="/sendInfos" component={SendInfos} />
          <Route exact path="/SendInfoDetail" component={SendInfoDetail} />
          <Route exact path="/FirstPage" component={FirstPage} />
          <Route exact path="/OnlineStep" component={OnlineStep} />
          <Route exact path='/' component={Home} />
        </Switch>
      </BrowserRouter>
    );
  }
}
