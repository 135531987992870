import React, { useEffect } from "react";

const Home = () => {
  const redirect_auth = "http://zskd.jxkp.com/authed";
  useEffect(() => {
    let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx2eff087732fd5871&redirect_uri=${encodeURIComponent(
      redirect_auth
    )}&response_type=code&scope=snsapi_base&state=1&connect_redirect=1#wechat_redirect`;
    window.location.href = url;
  }, []);
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span>系统加载中....</span>
    </div>
  );
};

export default Home;
