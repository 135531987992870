import React from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
const UploadedImage=styled.img`
    width:100%;
`;

const UploadOuter = styled.div`
    width: 46vw;
    background-color: #f1f1f1;
    border: 1px dotted #a5a5a5;
    text-align:center;
    &:hover{
        border-color:blue;
    }
    
`;
const UploadButton = ({ loading, text, imgBase64 }) => {
    

  const renderIconAndText = () => {
    return (
      <>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>{text}</div>
      </>
    );
  };
  return <UploadOuter>
      {imgBase64?(
          <UploadedImage src={imgBase64} />

      ): renderIconAndText()}
  </UploadOuter>;
};

export default UploadButton;
