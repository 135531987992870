import React, { useEffect,useState } from 'react'
import {Button, List} from 'antd'
import styled from 'styled-components';
import Layout from '../components/Layout';
import useWxAuth from '../hooks/useWxAuth';
import { post } from '../services/HttpService';
import moment from 'moment'
const SendInfoDetail = ({history}) => {
    
    const ItemOuter=styled.div`
        padding:0 10px;
        &>.label{
            font-size:14px;
            margin-right:6px;
            color:#888;
            font-weight:bold;
        }
    `;
    let {location}=history;
    let id = location.state.id;
    const [data, setData] = useState({})
    let {getUserCode} = useWxAuth()   
    const loadData=async (id)=>{
        let code = getUserCode();
        let postBody={
            code,
            id,
        };
        let postResult = await post('/userapi/GetInfoDetails',postBody);
        if(postResult.ok){
            setData(postResult.payload);
        }
    }
    useEffect(()=>{
        loadData(id);
    },[]);
    return (
        <Layout>
            <List>
                <List.Item>
                    <ItemOuter>

                    <span className='label'>姓名</span>
                    <span>{data.name}</span>
                    </ItemOuter>
                </List.Item>
               
                <List.Item>
                    <ItemOuter>

                    <span className='label'>电话</span>
                    <span>{data.tel}</span>
                    </ItemOuter>
                </List.Item>
                <List.Item>
                    <ItemOuter>

                    <span className='label'>地址</span>
                    <span>{data.address}</span>
                    </ItemOuter>
                </List.Item>
                <List.Item>
                    <ItemOuter>

                    <span className='label'>证书名称</span>
                    <span>{data.zsName&&data.zsName.name}</span>
                    </ItemOuter>
                </List.Item>
                <List.Item>
                    <ItemOuter>

                    <span className='label'>年份</span>
                    <span>{data.year}</span>
                    </ItemOuter>
                </List.Item>
                <List.Item>
                    <ItemOuter>

                    <span className='label'>是否已发出</span>
                    <span>{data.isSended?"已发出":"未发出"}</span>
                    </ItemOuter>
                </List.Item>
                <List.Item hidden={!data.isSended}>
                    <ItemOuter>

                    <span className='label' >快递公司</span>
                    <span>{data.senderCompany}</span>
                    </ItemOuter>
                </List.Item>
                <List.Item hidden={!data.isSended}>
                    <ItemOuter>

                    <span className='label' >快递单号</span>
                    <span>{data.sendNumber}</span>
                    </ItemOuter>
                </List.Item>
                <List.Item hidden={!data.isSended}>
                    <ItemOuter>

                    <span className='label' >发出日期</span>
                    <span>{moment(data.modifyTime).format('YYYY-MM-DD')}</span>
                    </ItemOuter>
                </List.Item>
               <List.Item style={{padding:'0 10px'}}>
                   
                       <Button type='primary' style={{width:'100%'}} htmlType='button' onClick={()=>history.go(-1)}>返回</Button>
                  
               </List.Item>
            </List>
        </Layout>
    )
}

export default SendInfoDetail;