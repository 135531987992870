import React from 'react'
import { post } from '../services/HttpService';
const storageId='wx_sender_token';
const useAuth = () => {
    const toLogin=async(token)=>{
        localStorage.setItem(storageId,token)
    }
    const quit=()=>{
        localStorage.setItem(storageId,'');
    }
    const getToken = ()=>{
        return localStorage.getItem(storageId);
    }
    const isLogin=async()=>{
        let token = localStorage.getItem(storageId);
        //console.log('token',token);
        if(!token){
            return false;
        }else{
            let da ={token};
            let res = await post('/auth/ValidateToken',da);
            return res.ok;
        }
    }
    
    return {
        toLogin,
        isLogin,
        getToken,
        quit
    }
}

export default useAuth
