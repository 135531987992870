import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Switch,
  Select,
  AutoComplete,
  Col,
  Row,
  Checkbox,
  message,
} from "antd";
import styled from "styled-components";
import useHttp from "../../hooks/useHttp";
import TextArea from "antd/lib/input/TextArea";
const SfImageOuter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  & > img {
    width: 46%;
    align-self: flex-start;
  }
`;
const InfoEdit = ({ info, onSave }) => {
  const editInfo = {
    ...info,
    isSended: !!info.isSended,
  };
  const { shNote } = editInfo;
  const [isSh, setIsSh] = useState(editInfo.isSh);
  const [showSNo, setShowSNo] = useState(editInfo.isSended);
  const [zsItems, setZsItems] = useState([]);
  const { authPost } = useHttp();
  const loadZsItems = async () => {
    let res = await authPost("/api/GetZsItems", {});
    if (res.ok) {
      setZsItems(res.payload);
    }
  };
  useEffect(() => {
    loadZsItems();
  }, []);
  const FormBtnWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  `;
  const onFinshed = async (value) => {
    if (onSave) {
      if(isSh===false && ! value.shNote){
        message.error('审核不通过请填写“审核不通过原因”');
        return;
      }
      let formData = {
        ...value,
        isSh:isSh
      };
      
      onSave(formData);
    }
  };
  const onIsSendedChange = (value) => {
    setShowSNo(value);
  };
  const renderZsItems = () => {
    return zsItems.map((it) => (
      <Select.Option key={it.id} value={it.id}>
        {it.name}
      </Select.Option>
    ));
  };
  const renderYearOptions = () => {
    let years = [];
    let date = new Date();

    for (let year = date.getFullYear(); year > 2005; year--) {
      years.push(year);
    }
    return years.map((y) => (
      <Select.Option value={y} key={y}>
        {y}
      </Select.Option>
    ));
  };
  const changeShStatus = (val) => {
    setIsSh(val);
    // let newValue = null;
    // if (isSh === null) {
    //   newValue = true;
    // } else if (isSh === true) {
    //   newValue = false;
    // } else {
    //   newValue = null;
    // }
    // setIsSh(newValue);
  };
  return (
    <div style={{ margin: "15px" }}>
      <Form
        onFinish={onFinshed}
        initialValues={editInfo}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item label="姓名" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="地址" name="address">
          <Input />
        </Form.Item>
        <Form.Item label="联系电话" name="tel">
          <Input />
        </Form.Item>
        <Form.Item label="证书名称" name="zsId">
          <Select
            showSearch
            filterOption={(input, option) =>
              option.children.indexOf(input) >= 0
            }
          >
            {renderZsItems()}
          </Select>
        </Form.Item>
        <Form.Item label="年份" name="year">
          <Select>{renderYearOptions()}</Select>
        </Form.Item>
        <Form.Item name="note" label="备注">
          <Input.TextArea />
        </Form.Item>
        <Form.Item>
          <SfImageOuter>
            <img src={info.sf1} />
            <img src={info.sf2} />
          </SfImageOuter>
        </Form.Item>
        <Form.Item label="快递已发" name="isSended">
          <Switch
            checkedChildren="已发"
            checked={showSNo}
            unCheckedChildren="未发"
            onChange={onIsSendedChange}
          />
        </Form.Item>
        <Form.Item name="sendNumber" label="快递单号" hidden={!showSNo}>
          <Input />
        </Form.Item>
        <Form.Item name="senderCompany" label="快递公司" hidden={!showSNo}>
          <AutoComplete>
            {["顺丰快递", "EMS"].map((it) => (
              <AutoComplete.Option key={it} value={it}>
                {it}
              </AutoComplete.Option>
            ))}
          </AutoComplete>
        </Form.Item>
        <Form.Item label="审核状态">
          <Checkbox
            checked={isSh}
            onClick={()=>changeShStatus(true)}
          >
            审核通过
          </Checkbox>
          <Checkbox
              checked={isSh===false}
              onClick={()=>changeShStatus(false)}
          >
            审核不通过
          </Checkbox>
          {/*<Checkbox*/}
          {/*  indeterminate={isSh === null}*/}
          {/*  checked={isSh}*/}
          {/*  onClick={changeShStatus}*/}
          {/*>*/}
          {/*  {isSh === true*/}
          {/*    ? "审核通过"*/}
          {/*    : isSh === null*/}
          {/*    ? "未审核"*/}
          {/*    : "审核不通过"}*/}
          {/*</Checkbox>*/}
        </Form.Item>
        {isSh === false && (
          <Form.Item label="审核不通过原因" name="shNote">
            <Input.TextArea />
          </Form.Item>
        )}
        <Form.Item>
          <FormBtnWrap>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
            <Button type="default" htmlType="button">
              取消
            </Button>
          </FormBtnWrap>
        </Form.Item>
      </Form>
    </div>
  );
};

export default InfoEdit;
