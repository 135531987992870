import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import img1 from "../images/s1.png";
import img2 from "../images/s2.png";
import img3 from "../images/s3.png";
import img4 from "../images/s4.png";
import img5 from "../images/s5.png";
const OnlineStep = () => {
  const StepImage = styled.img`
    width: 100%;
    margin-bottom: 8px;
    display: block;
  `;
  const PageOuter = styled.div`
    margin: 8px;
  `;
  const TxtOuter = styled.div`
    padding: 10px;
    line-height: 180%;
    font-size: 1.1rem;
    border: 1px solid #ffb21a;
    margin-bottom: 15px;
    background: #ffe9be;
    color: #ad101f;
    border-radius: 15px;
  `;
  return (
    <Layout>
      <PageOuter>
        <TxtOuter>
          内容更新中...
        </TxtOuter>
        {/*<StepImage src={img1} />*/}
        {/*<StepImage src={img2} />*/}
        {/*<StepImage src={img3} />*/}
        {/*<StepImage src={img4} />*/}
        {/*<StepImage src={img5} />*/}
      </PageOuter>
    </Layout>
  );
};

export default OnlineStep;
