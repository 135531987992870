import React, { useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { post } from "../services/HttpService";
const user_code_storid = "user_wx_code";

const useWxAuth = () => {
  const location = useLocation();
  const setUserCode = (code) => {
    localStorage.setItem(user_code_storid, code);
  };
  const getUserCode = () => {
    let code = localStorage.getItem(user_code_storid);
    return code;
  };
  const refreshToGetUserCode = () => {
    let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx2eff087732fd5871&redirect_uri=${encodeURIComponent(
      window.location.href
    )}&response_type=code&scope=snsapi_base&state=1&connect_redirect=1#wechat_redirect`;
    window.location.href = url;
  };
  const chkWxIsAuthed = () => {
    let code = getUserCode();
    if (!code) {
      refreshToGetUserCode();
    }
  };
  const authUserCode = async (userCode) => {
    let res = await post("/userapi/AuthOpenId", { code: userCode });

    if (res.ok) {
      //setUserCode(userCode);
      return true;
    } else {
      return false;
    }
  };
  const initCodeFromQuery = async () => {
    let { search } = location;
    let code = "";
    if (search && search.includes("?")) {
      let params = search
        .replace("?", "")
        .split("&")
        .map((it) => it.split("="))
        .find((it) => it[0] === "code");
      if (params) {
        code = params[1];
        if (await authUserCode(code)) {
          setUserCode(code);
        }
      }
    }
  };
  useEffect(() => {
    initCodeFromQuery();
    return () => {};
  }, []);
  return {
    setUserCode,
    getUserCode,
    chkWxIsAuthed,
  };
};

export default useWxAuth;
