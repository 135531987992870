import React from 'react'
import TopMenu from './TopMenu'

const Layout = ({children}) => {
    return (
        <div>
            <TopMenu/>
            <div style={{marginTop:'44px'}}>

            {children}
            </div>
        </div>
    )
}

export default Layout
