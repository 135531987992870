import React, { useEffect, useState } from "react";
import { Form, Select, Input, Button, message, Upload } from "antd";

import styled from "styled-components";
import { post } from "../services/HttpService";
import Layout from "../components/Layout";
import useWxAuth from "../hooks/useWxAuth";
import UploadButton from "../components/UploadButton";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

const OuterWrap = styled.div`
  padding: 15px;
`;
const UnSubscribeOuter = styled.div`
  background: #f9f9e4;
  padding: 12px;
  line-height: 150%;
  color: #694806;
  border: 1px dotted #9c0000;
`;

const EnterInfo = () => {
  const history = useHistory();
  const { getUserCode } = useWxAuth();
  const [zsItems, setZsItems] = useState([]);
  const [isSubscribe, SetIsSubscribe] = useState(false);
  const [pageIsLoading,setPageIsloading]=useState(false);
  const [loading, setLoading] = useState({ sf1: false, sf2: false });
  const [images, setImages] = useState({ sf1: "", sf2: "" });
  const getRequiredMessage = (msg) => {
    return [
      {
        required: true,
        message: msg,
      },
    ];
  };
  const loadData = async () => {
    setPageIsloading(true);
    let res = await post("/userApi/GetZsItems", {});
    setPageIsloading(false);
    if (res.ok) {
      setZsItems(res.payload);
    }
  };
  const getUserSubscribeStatus = async () => {
    let res = await post("/userApi/GetUserWxSubscribeStatus", {
      code: getUserCode(),
    });
    SetIsSubscribe(res.ok);
  };
  useEffect(() => {
    loadData();
    getUserSubscribeStatus();
  }, []);
  const onFinished = async (values) => {
    let code = getUserCode();
    let da = {
      ...values,
      code,
      ...images,
    };
    if (!images.sf1) {
      message.error("请上传身份证正面");
      return;
    }
    if (!images.sf2) {
      message.error("请上传身份证背面");
      return;
    }
    setPageIsloading(true);

    let res = await post("/userApi/SubmitInfo", da);

    setPageIsloading(false);
    if (res.ok) {
      message.success("提交信息成功");
      history.replace("/FirstPage");
    } else {
      message.error(res.message);
    }
  };
  const renderZsOptions = () => {
    return zsItems.map((it) => (
      <Select.Option key={it.id} value={it.id}>
        {it.name}
      </Select.Option>
    ));
  };
  const renderYearOptions = () => {
    let years = [];
    let date = new Date();

    for (let year = date.getFullYear(); year > 2005; year--) {
      years.push(year);
    }
    return years.map((y) => (
      <Select.Option value={y} key={y}>
        {y}
      </Select.Option>
    ));
  };
  const setUploadLoading = (value, imageId) => {
    let loadingValue = {
      ...loading,
      [imageId]: value,
    };
    setLoading(loadingValue);
  };
  const onUploadChange = (info, imageId) => {
    if (info.file.status === "uploading") {
      setUploadLoading(true, imageId);
      return;
    }
    if (info.file.status === "done") {
      setUploadLoading(false, imageId);

      let sfzImages = {
        ...images,
        [imageId]: info.file.response.payload,
      };

      setImages(sfzImages);
    }
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("请上传正确的图片格式，支持的格式有JPG PNG");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("图片大小必须小于5MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const renderNotSubscribeMsg = () => {
    if (isSubscribe) {
      return null;
    } else {
      return (
        <UnSubscribeOuter>
          您未关注“嘉兴考试培训网服务号”公众号，无法提交申请，关注操作引导说明{" "}
          <Link to="/subscribeNote">点击这里查看</Link>
        </UnSubscribeOuter>
      );
    }
  };
  return (
    <Layout>
      <OuterWrap>
        {renderNotSubscribeMsg()}
        <Form onFinish={onFinished}>
          <Form.Item
            name="name"
            label="姓名"
            rules={getRequiredMessage("请填写姓名")}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="tel"
            label="联系电话"
            rules={getRequiredMessage("请填写联系电话")}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="address"
            label="地址"
            rules={getRequiredMessage("请填写地址")}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="zsId"
            label="证书名称"
            rules={getRequiredMessage("请填写证书名称")}
          >
            <Select
              showSearch
              placeholder="请选择"
              filterOption={(input, option) =>
                option.children.indexOf(input) >= 0
              }
            >
              {renderZsOptions()}
            </Select>
          </Form.Item>
          <Form.Item
            name="year"
            label="年份"
            rules={getRequiredMessage("请填写年份")}
          >
            <Select>{renderYearOptions()}</Select>
          </Form.Item>
          <Form.Item label="身份证照片">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "space-around",
              }}
            >
              <Upload
                name="files"
                showUploadList={false}
                beforeUpload={beforeUpload}
                action="/userapi/Upload"
                onChange={(info) => onUploadChange(info, "sf1")}
                capture="camera"
              >
                <UploadButton
                  text="身份证正面"
                  loading={loading.sf1}
                  imgBase64={images.sf1}
                />
              </Upload>
              <Upload
                capture="camera"
                name="files"
                showUploadList={false}
                beforeUpload={beforeUpload}
                action="/userapi/Upload"
                onChange={(info) => onUploadChange(info, "sf2")}
              >
                <UploadButton
                  text="身份证背面"
                  loading={loading.sf2}
                  imgBase64={images.sf2}
                />
              </Upload>
            </div>
          </Form.Item>
          <Form.Item name="note" label="备注信息">
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            {isSubscribe ? (
              <Button
                htmlType="submit"
                disabled={pageIsLoading}
                type="primary"
                style={{ width: "100%" }}
              >
                提交信息
              </Button>
            ) : (
              <Button htmlType="button" type="primary" disabled>
                您未关注“嘉兴考试培训网服务号”公众号，无法提交申请
              </Button>
            )}
          </Form.Item>
        </Form>
      </OuterWrap>
    </Layout>
  );
};

export default EnterInfo;
