import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import useWxAuth from "../hooks/useWxAuth";
import { post } from "../services/HttpService";

const Authed = ({ match, location, history, ...props }) => {
  let { search } = useLocation();
  let [isAuthed, setIsAuthed] = useState(false);
  const { setUserCode } = useWxAuth();
  const getSearchCode = () => {
    let code = "";
    if (search && search.includes("?")) {
      let params = search
        .replace("?", "")
        .split("&")
        .map((it) => it.split("="))
        .find((it) => it[0] === "code");
      if (params) {
        code = params[1];
      }
    }
    return code;
  };
  const authUserCode = async () => {
    let code = getSearchCode();
    if (!code) {
      console.log("code is empty");
      return;
    }
    if (isAuthed) {
      setUserCode(code);
      window.location.href = "/FirstPage";
      return;
    }
    let res = await post("/userapi/AuthOpenId", { code });

    if (res.ok) {
      setUserCode(code);
      window.location.href = "/FirstPage";
    } else {
      console.log("authed code false");
      window.setTimeout(() => {
        window.location.reload(true);
      }, 200);
    }
  };
  useEffect(() => {
    authUserCode();
  }, []);
  return <div></div>;
};

export default Authed;
